import { baseTrackEvent, baseTrackPage } from '@/utils/analytics';
import AnalyticsEvents, {
  AnalyticsEventsType,
} from '@/config/analytics-events';
import { useMediaQuery, useTheme } from '@drivekyte/web-components';

const useAnalyticsWithSource = () => {
  const [theme] = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme?.breakpoints?.sm})`);

  const trackEvent = (
    eventName: AnalyticsEventsType | string,
    eventPayload?: { [property: string]: unknown },
  ) => {
    baseTrackEvent(eventName, {
      source: isMobile ? 'web_mobile' : 'web_desktop',
      ...eventPayload,
    });
  };

  const trackPageView = (
    pageName: string,
    eventPayload?: { [property: string]: unknown },
  ) => {
    baseTrackPage(pageName, {
      source: isMobile ? 'web_mobile' : 'web_desktop',
      ...eventPayload,
    });
  };

  return {
    trackEvent,
    trackPageView,
    AnalyticsEvents,
  };
};

export default useAnalyticsWithSource;
