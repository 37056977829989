enum BookingFlow {
  CarPickerVehicleAvailability = 'car_picker_vehicles_displayed',
  CheckoutBookButtonClick = 'checkout:booking:clicked',
  CheckoutOrderPlaced = 'checkout:booking:order_placed',
  CheckoutOrderFailed = 'checkout:booking:error',
  CheckoutBookingSuccess = 'checkout:booking:success',
  CheckoutFirstBookingSuccess = 'First Booking',
  CheckoutRepeatedBookingSuccess = 'Repeated Booking',
  CheckoutPriceDetailsClick = 'checkout:price_details_button:clicked',
  CheckoutAirportInstructionsClick = 'checkout:airport_instructions:clicked',
  CheckoutMinimumAgeRequirementClick = 'checkout:minimum_age_requirement:clicked',
  CheckoutCancellationPolicyClicked = 'checkout:cancellation_policy:clicked',
  CheckoutPromoButtonClicked = 'checkout:promo_button:clicked',
  CheckoutViewContractButtonClicked = 'checkout:view_contract_button:clicked',
  CoverageDamageDetailsClicked = 'checkout:damage_details_button:clicked',
  CoverageLiabilityClicked = 'checkout:liability_details:clicked',
  CoverageDamageToggled = 'checkout:damage_button:toggled',
  CoverageLiabilityToggled = 'checkout:liability_button:toggled',
  PickUpProcessInstructionsClicked = 'checkout:pick_up_instructions:clicked',
  RefineAddressDialogDismissed = 'Refine Address Dialog Dismissed',
  RefineAddressDialogSubmitted = 'Refine Address Dialog Submitted',
  RefineAddressDialogShown = 'Refine Address Dialog Shown',
  InvalidAddressWarningClicked = 'Invalid Address Warning Clicked',
  // BookingFlowV2
  SearchBarClicked = 'Search Bar Clicked',
  SearchAddressType = 'Search Address Type',
  SearchAddressSubmitted = 'Search Address Submitted',
  SearchDateSubmitted = 'Search Date Submitted',
  SearchSubmitted = 'Search Submitted',
  CarPickerVehiclesDisplayed = 'Car Picker Vehicles Displayed',
  CarPickerSubmitted = 'Car Picker Submitted',
  CarPickerSoldOutPageViewed = 'Car Picker Sold Out Page Viewed',
  CarPickerSoldOutCTAClicked = 'Car Picker Sold Out CTA Clicked',
  FleetStandardsClicked = 'Fleet Standards Clicked',
  TransparentFeesClicked = 'Transparent Fees Clicked',
  CarPickerCarDetailsOpened = 'Car Picker Car Details Opened',
  CarPickerErrorCardViewed = 'Car Picker Error Card Viewed',
  CarPickerErrorCardCTAClicked = 'Car Picker Error Card CTA Clicked',
}

enum GuestCheckout {
  AppDownloadClick = 'guest_checkout:app_download:clicked',
  ResendEmailClick = 'guest_checkout:resend_email:clicked',
  BookingAndPayClicked = 'guest_checkout:book_and_pay:clicked',
  BookingError = 'guest_checkout:booking:error',
  FirstNameEntered = 'guest_checkout:first_name:entered',
  LastNameEntered = 'guest_checkout:last_name:entered',
  PhoneNumberEntered = 'guest_checkout:phone_number:entered',
  EmailEntered = 'guest_checkout:email:entered',
  ConfirmEmailEntered = 'guest_checkout:confirm_email:entered',
  CardNumberEntered = 'guest_checkout:card_number:entered',
  CardExpiryEntered = 'guest_checkout:expiry:entered',
  CardCvvEntered = 'guest_checkout:cvv:entered',
  CardZipCodeEntered = 'guest_checkout:zip_code:entered',
}

enum Subscription {
  BreadcrumbsWhereAndWhenClick = 'breadcrumbs:where_and_when:clicked',
  BreadcrumbsChooseCarClick = 'breadcrumbs:choose_a_car:clicked',
  MainSearchSubmit = 'main_search:subscription_search:submitted',
  TeslaSearchSubmit = 'tesla_search:subscription_search:submitted',
  CheckoutMonthsOptionClick = 'sub_checkout:months_option:clicked',
  CheckoutMilesOptionClick = 'sub_checkout:miles_option:clicked',
  CheckoutLiabilityOptionClick = 'sub_checkout:liability_option:clicked',
  TripDetailsChangeDateClick = 'sub_trip:change_date_button:clicked',
  CheckoutSeeGalleyButtonClick = 'sub_checkout:car_gallery_button:clicked',
  CheckoutBookingError = 'sub_checkout:booking:error',
  CheckoutBookingSuccess = 'sub_checkout:booking:success',
  CheckoutBookButtonClick = 'sub_checkout:book_button:clicked',
  CheckoutSoftCheckDialogClick = 'sub_checkout:soft_check_dialog:clicked',
  ServiceAreaSelected = 'Subscription Service Area Selected',
}

enum BetterInstructionsEvents {
  LogInDownloadClicked = 'instructions_login_download_clicked',
  ScreenViewed = 'instructions_screen_viewed',
  ModalInstructionsDisplayed = 'instructions_modal_displayed',
  SendDownloadLinkClicked = 'instructions_send_link_clicked',
}

enum SharedTripEvents {
  TripInviteAcceptClicked = 'user:trip_invite_accept:clicked',
  TripInviteSignupClicked = 'user:trip_invite_sign_up:clicked',
  TripInviteAddPersonalDataClicked = 'user:trip_invite_personal_data:clicked',
  TripInviteProfileFormSubmitted = 'Trip Invite Profile Form Submitted',
  TripInviteProfileFormSuccess = 'Trip Invite Profile Form Success',
  TripInviteAppVerificationClicked = 'Trip Invite App Verification Clicked',
}

enum Priceline {
  Error = 'priceline:error',
  SetPasswordClicked = 'priceline:set-password:click',
  Success = 'priceline:set-password:success',
  VerificationLinkClicked = 'user:verification_link:clicked',
  VerificationInstructionsClicked = 'user:verification_instructions:clicked',
  PricelineUseBrowserClicked = 'Priceline Use Browser Clicked',
}

enum Verification {
  VerificationInstructionsDisplayed = 'Verification Instructions Displayed',
  TripDetailsVerifyIdentificationClicked = 'Trip Details Verify Identification Clicked',
  TripDetailsUseAppClicked = 'Trip Details Use App Clicked',
  VerificationBrowserSuccess = 'Verification Browser Success',
  VerificationAppSuccess = 'Verification App Success',
  VerificationSubmitted = 'Verification Submitted',
  VerificationClosed = 'Verification Closed',
  VerificationErrored = 'Verification Errored',
}

enum CoverageBundles {
  TripCoverageEditClicked = 'trip_details:coverage_edit:clicked',
  TripCoverageCtaClicked = 'trip_details:coverage_cta:clicked',
  TripRoadsideAssistanceDialogDisplayed = 'trip_details:roadside_assistance_dialog:displayed',
  TripRoadsideAssistanceCtaClicked = 'trip_details:roadside_assistance_cta:clicked',
}

enum RentalAgreements {
  ViewRentalAgreement = 'rental_agreements:agreement:view',
  SignRentalAgreement = 'rental_agreements:agreement:signature',
  ViewVehicleDamage = 'rental_agreements:agreement:view_damage',
}

enum FrontendExperiments {
  ExperimentTriggered = 'experiment:triggered',
  ExperimentError = 'experiment:error',
  ExperimentEvent = 'experiment:event,',
}

enum UniqueRenters {
  PhoneVerificationOtpRequested = 'Phone Verification Otp Requested',
  PhoneVerificationOtpResent = 'Phone Verification Otp Resent',
  PhoneVerificationOtpSubmitted = 'Phone Verification Otp Submitted',
}

enum AirportInstructionsEvents {
  AirportCheckoutViewed = 'airport:checkout:viewed',
  AirportCheckoutDeliveryCTAClicked = 'airport:checkout:delivery_cta:clicked',
  AirportCheckoutReturnCTAClicked = 'airport:checkout:return_cta:clicked',
  AirportCheckoutDisclaimerViewed = 'airport:checkout:disclaimer_modal:viewed',
  AirportCheckoutDisclaimerBoxToggled = 'airport:checkout:disclaimer_box:toggled',
  AirportCheckoutDisclaimerCTAClicked = 'airport:checkout:disclaimer_cta:clicked',
  AirportTripViewed = 'airport:trip:viewed',
  AirportTripDeliveryCTAClicked = 'airport:trip:delivery_cta:clicked',
  AirportTripReturnCTAClicked = 'airport:trip:return_cta:clicked',
  AirportHowItWorksModalViewed = 'airport:how_it_works_modal:viewed',
  AirportHowItWorksCTAClicked = 'airport:how_it_works_modal_cta:clicked',
  AirportInstructionsArticleDisplayed = 'Airport Instructions Article Displayed',
}

enum SignInEvents {
  SignupError = 'user:signup:error',
  SignInSuccess = 'user:login:success',
  SigninError = 'user:login:error',
  SignOutSuccess = 'user:signout:success',
  SignOutError = 'user:signout:error',
}

enum SelfPickupEvents {
  SelfPickupDisplayed = 'PUL Getting The Vehicle Displayed',
  SelfPickupDeliveryClicked = 'PUL Delivery Clicked',
  SelfPickupDeliveryAddressSubmitted = 'PUL Delivery Address Submitted',
  SelfPickupDeliveryAddressCancelled = 'PUL Delivery Address Cancelled',
  SelfPickupPickupClicked = 'PUL Pickup Clicked',
  SelfPickupPickupAddressSubmitted = 'PUL Pickup Address Submitted',
  SelfPickupPickupAddressCancelled = 'PUL Pickup Address Cancelled',
  SelfPickupInstructionsClicked = 'PUL Instructions Clicked',
  SelfPickupCouponCodeBlocked = 'PUL Coupon Code Blocked',
}

enum PosthogEvents {
  PosthogOverride = 'PosthogId Overrided',
}

enum LiveDataSocialProofEvents {
  LiveDataSocialProofDisplayed = 'LDSP Displayed',
  LiveDataSocialProofClicked = 'LDSP Clicked',
  LiveDataSocialProofDisabled = 'LDSP Disabled',
}

enum HandoverOutpostEvents {
  HandoverOutpostDisplayed = 'Handover Outpost Displayed',
  HandoverOutpostAccepted = 'Handover Outpost Accepted',
}

enum Search {
  CityTagSelected = 'Search City Tag Selected',
  CityTagDisplayed = 'Search City Tag Displayed',
  SuggestionClicked = 'Search Suggestion Clicked',
  RecentSearchClicked = 'Recent Search Clicked',
  VagueSearchRedirectedToLot = 'Vague Search Redirected To Lot',
}

enum Support {
  OpenUrl = 'Support Open URL',
  IntercomLaunched = 'Intercom Launched',
}

enum Auth0 {
  SigninRedirect = 'Auth0 Signin Redirect',
  SignoutRedirect = 'Auth0 Signout Redirect',
  SigninRedirectSuccess = 'Auth0 Signin Redirect Success',
  SignoutRedirectSuccess = 'Auth0 Signout Redirect Success',
}

enum EditTripEvents {
  ReschedulingFeeDisplayed = 'Rescheduling Fee Displayed',
  ReschedulingFeeDismissed = 'Rescheduling Fee Dismissed',
  ReschedulingFeeAccepted = 'Rescheduling Fee Accepted',
  EditTripPoliciesOpened = 'Edit Trip Policies Opened',
  EditTripPriceDifferenceOpened = 'Edit Trip Price Difference Opened',
}

enum CheckoutV2Events {
  // Checkout page events
  CheckoutContentLoading = 'Checkout Content Loading',
  CheckoutContentError = 'Checkout Content Error',
  CheckoutContentDisplayed = 'Checkout Content Displayed',
  CheckoutDisclaimerModalsDisplayed = 'Checkout Disclaimer Modals Displayed',
  CheckoutWelcomeBannerDisplayed = 'Checkout Welcome Banner Displayed',

  // Form submit events
  CheckoutFormSubmitted = 'Checkout Form Submitted',
  CheckoutFormError = 'Checkout Form Error',
  CheckoutFormSuccess = 'Checkout Form Success',
  CheckoutDriverUpdate = 'Checkout Driver Update',
  CheckoutDriverUpdateError = 'Checkout Driver Update Error',
  CheckoutDriverUpdateSuccess = 'Checkout Driver Update Success',
  CheckoutPaymentUpdate = 'Checkout Payment Update',
  CheckoutPaymentUpdateError = 'Checkout Payment Update Error',
  CheckoutPaymentUpdateSuccess = 'Checkout Payment Update Success',

  // Booking events
  BookingSubmitted = 'Booking Submitted',
  BookingError = 'Booking Error',
  BookingSuccess = 'Booking Success',
  OrderCompleted = 'Order Completed',

  // Driver section events
  DriverSigninClicked = 'Driver Signin Clicked',
  DriverInfoDisplayed = 'Driver Info Displayed',
  DriverFormDisplayed = 'Driver Form Displayed',
  YoungRenterToggled = 'Young Renter Toggled',

  // Trip section events
  // See SelfPickupEvents enum for remaining events
  CarClassEditCtaClicked = 'Car Class Edit CTA Clicked',
  CarClassChanged = 'Car Class Changed',
  TripEditStartCtaClicked = 'Trip Edit Start CTA Clicked',
  TripEditEndCtaClicked = 'Trip Edit End CTA Clicked',
  TripEditSubmitted = 'Trip Edit Submitted',
  DeliveryMethodSubmitted = 'Delivery Method Submitted',

  // Coverage section events
  // See CoverageBundles enum for remaining events
  UnlimitedMilesToggled = 'Unlimited Miles Toggled',

  // Protection section event
  CheckoutProtectionDisplayed = 'Checkout Protection Displayed',
  CheckoutProtectionPlanSelected = 'Checkout Protection Plan Selected',
  CheckoutProtectionDeclineDialogOpened = 'Protection Decline Dialog Opened',
  CheckoutProtectionDeclined = 'Checkout Protection Declined',
  CheckoutProtectionDeclineDialogUpgradeMinimum = 'Protection Decline Upgrade Minimum',
  CheckoutProtectionDisclaimerDialogOpened = 'Protection Disclaimer Opened',
  CheckoutProtectionDisclaimerDialogClosed = 'Protection Disclaimer Closed',
  CheckoutProtectionDisclaimerDialogButtonPressed = 'Protection Disclaimer Button Pressed',

  // Payment section events
  PaymentInfoDisplayed = 'Payment Info Displayed',
  PaymentFormDisplayed = 'Payment Form Displayed',
  PaymentFormValidated = 'Payment Form Validated',
  PaymentFormError = 'Payment Form Error',
  PaymentChangeCtaClicked = 'Payment Change CTA Clicked',
  PaymentChanged = 'Payment Changed',
  CreateStripeTokenSuccess = 'Create Stripe Token Success',
  CreateStripeTokenError = 'Create Stripe Token Error',
  PaymentMissingInBookingCart = 'Payment Missing In Booking Cart',
  PaymentAddedToBookingCart = 'Payment Added To Booking Cart',
  PaymentSubmitted = 'Payment Submitted',

  // Promo code events
  PromoCodeAddCtaClicked = 'Promo Code Add Cta Clicked',
  PromoCodeSubmitted = 'Promo Code Submitted',
  PromoCodeError = 'Promo Code Error',
  PromoCodeSuccess = 'Promo Code Success',

  // Additional Payment Methods
  AuthenticatedPaymentSetupSubmitted = 'Authenticated Payment Setup Submitted',
  AuthenticatedPaymentSetupSuccess = 'Authenticated Payment Setup Success',
  AuthenticatedPaymentSetupError = 'Authenticated Payment Setup Error',
  GuestPaymentSetupSubmitted = 'Guest Payment Setup Submitted',
  GuestPaymentSetupSuccess = 'Guest Payment Setup Success',
  GuestPaymentSetupError = 'Guest Payment Setup Error',
}

enum Navigation {
  NavSupportButtonClicked = 'nav:support_button:clicked',
  NavSignOutButtonClicked = 'nav:sign_out_button:clicked',
  NavSignUpButtonClicked = 'nav:sign_up_button:clicked',
  NavDownloadAppButtonClicked = 'nav:download_app_button:clicked',
  NavHowKyteWorksClicked = 'nav:how-kyte-works:clicked',
  NavTripsClicked = 'nav:trips:clicked',
  NavAccountClicked = 'nav:account:clicked',
  NavReferralClicked = 'nav:referrals:clicked',
  NavFaqClicked = 'nav:faq:clicked',
  NavBecomeASurferClicked = 'nav:become_a_surfer:clicked',
}

enum StripePaymentElementsEvents {
  StripePaymentDisplayed = 'Stripe Payment Displayed',
  StripePaymentSubmitError = 'Stripe Payment Submit Error',
  StripePaymentProcessError = 'Stripe Payment Processing Error',
  StripePaymentConfirmError = 'Stripe Payment Confirm Error',
  StripePaymentValidationError = 'Stripe Payment Validation Error',
  StripePaymentResubmitSuccess = 'Stripe Payment Resubmit Success',
  StripePaymentConfirmSuccess = 'Stripe Payment Confirm Success',
}

enum UnpaidBalanceEvents {
  UnpaidBalanceDisplayed = 'Unpaid Balance Displayed',
  UnpaidBalanceClicked = 'Unpaid Balance Clicked',
}

/**
 * Ensure that all event changes are documented in the V1 tracking sheet:
 * https://docs.google.com/spreadsheets/d/1eO63qoKi2iWGPK9QXQZWzYZukkoabM3onUohIzy-jEk/edit#gid=1114892194
 */
const AnalyticsEvents = {
  AirportInstructionsEvents,
  Auth0,
  BetterInstructionsEvents,
  BookingFlow,
  EditTripEvents,
  GuestCheckout,
  CheckoutV2Events,
  CoverageBundles,
  FrontendExperiments,
  HandoverOutpostEvents,
  LiveDataSocialProofEvents,
  Priceline,
  PosthogEvents,
  RentalAgreements,
  Search,
  SelfPickupEvents,
  SharedTripEvents,
  SignInEvents,
  Subscription,
  Support,
  Verification,
  Navigation,
  StripePaymentElementsEvents,
  UniqueRenters,
  UnpaidBalanceEvents,
};

export type AnalyticsEventsType =
  | BookingFlow
  | BetterInstructionsEvents
  | EditTripEvents
  | Subscription
  | GuestCheckout
  | SharedTripEvents
  | Priceline
  | CoverageBundles
  | RentalAgreements
  | FrontendExperiments
  | AirportInstructionsEvents
  | Auth0
  | BetterInstructionsEvents
  | BookingFlow
  | CheckoutV2Events
  | CoverageBundles
  | FrontendExperiments
  | GuestCheckout
  | HandoverOutpostEvents
  | LiveDataSocialProofEvents
  | Priceline
  | PosthogEvents
  | RentalAgreements
  | Search
  | SelfPickupEvents
  | SharedTripEvents
  | SignInEvents
  | Subscription
  | Support
  | Verification
  | Navigation
  | StripePaymentElementsEvents
  | UniqueRenters
  | UnpaidBalanceEvents;

export default AnalyticsEvents;
