/* eslint-disable import/namespace */
import * as Flags from '@drivekyte/flags';
import { UseSelectStateChange, useSelect } from 'downshift';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { Box } from '../box';
import CountryList from './components/web/country-list';
import { PhoneInputBase } from './phone-input.base';
import { Country, countries } from './phone-input.constants';
import { PhoneInputProps } from './phone-input.types';
import { getCountryCodeFromNumber } from './phone-input.utils';

export const PhoneInput = ({
  searchPlaceholder,
  value,
  onChangeText,
  initialValue,
  ...props
}: PhoneInputProps) => {
  const [selectedCountry, setCountry] = useState(countries[0]);
  const [searchValue, setSearchValue] = useState('');
  const [listCountries, setListCountries] = useState([...countries]);

  const localRef = useRef<HTMLInputElement>();

  const handleSelectCountry = ({
    selectedItem,
  }: UseSelectStateChange<Country>) => {
    if (selectedItem) {
      if (value) {
        onChangeText?.(
          value.replace(
            new RegExp(`\\+${selectedCountry.value}`, 'g'),
            `+${selectedItem.value}`,
          ),
        );
      }
      setCountry(selectedItem);
      localRef.current?.focus();
    }
  };

  const {
    getMenuProps,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    toggleMenu,
    isOpen,
    closeMenu,
  } = useSelect({
    items: listCountries,
    onSelectedItemChange: handleSelectCountry,
  });

  useEffect(() => {
    if (initialValue) {
      const code = getCountryCodeFromNumber(initialValue);
      const countryCode = countries.find((item) => item.code === code);
      if (countryCode) setCountry(countryCode);
    }
  }, [initialValue]);

  const filterCountries = () => {
    if (!searchValue) {
      setListCountries([...countries]);
    } else {
      setListCountries(
        countries.filter((item) => {
          const normalizedText = item.text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          return normalizedText.includes(searchValue.toLowerCase());
        }),
      );
    }
  };

  useDebounce(filterCountries, 600, [searchValue, countries]);
  const Icon = Flags[selectedCountry.startIconName];

  return (
    <Box {...getToggleButtonProps({ disabled: true })}>
      <PhoneInputBase
        {...props}
        FlagIcon={<Icon />}
        country={selectedCountry}
        searchPlaceholder=""
        onPressFlag={toggleMenu}
        value={value}
        onChangeText={onChangeText}
        onFocus={closeMenu}
      />
      <CountryList
        getMenuProps={getMenuProps}
        countries={listCountries}
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
        selectedItem={selectedCountry}
        isOpen={isOpen}
        searchPlaceholder={searchPlaceholder}
        searchValue={searchValue}
        onChangeSearch={setSearchValue}
      />
    </Box>
  );
};
