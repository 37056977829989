import { Controller, useFormContext } from 'react-hook-form';
import { PhoneInput, Box, Button, ensurePlusSign } from '@drivekyte/ui';
import {
  CLEAN_PHONE_NUMBER_REGEX,
  CLEAN_PHONE_NUMBER_FORMAT_REGEX,
} from '@/utils/validations/constants/regex-patterns';
import { UniqueRentersState } from '@/types/unique-renters';
import {
  UniqueRentersStatus,
  UniqueRentersStatusVariantEnum,
} from '@/hooks/use-unique-renters/use-unique-renters';
import { useMemo } from 'react';

type PhoneConfirmationFormProps = {
  content: UniqueRentersState;
  verificationStatus?: UniqueRentersStatus;
  onSubmit: () => void;
  isLoading: boolean;
};

const PhoneConfirmationForm = ({
  content,
  isLoading,
  verificationStatus,
  onSubmit,
}: PhoneConfirmationFormProps) => {
  const { control, formState } = useFormContext();

  const showError =
    verificationStatus?.status === UniqueRentersStatusVariantEnum.Error &&
    !isLoading;

  const sanitizeInput = (input) =>
    ensurePlusSign(input.replace(CLEAN_PHONE_NUMBER_REGEX, ''));

  const errors = formState.errors;

  const error = useMemo(() => {
    if (showError) {
      return verificationStatus.message;
    }

    if (errors.phone && content?.fields.phone?.error) {
      return content?.fields.phone?.error;
    }

    return undefined;
  }, [
    showError,
    verificationStatus?.status,
    verificationStatus?.message,
    errors.phone,
    content,
  ]);

  return (
    <Box marginBottom="$medium">
      <Box marginBottom="$large">
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: CLEAN_PHONE_NUMBER_FORMAT_REGEX,
          }}
          render={({ field: { name, onBlur, onChange, value, ref } }) => (
            <PhoneInput
              name={name}
              value={value}
              onBlur={onBlur}
              autoComplete="tel"
              state={error ? 'error' : 'default'}
              hintText={error}
              label={content?.fields.phone?.label}
              onChange={(e) => {
                onChange(sanitizeInput(e.nativeEvent.text));
              }}
              // @ts-ignore
              ref={ref}
              type="tel"
              zIndex={100}
            />
          )}
        />
      </Box>
      <Box flexDirection="row" justifyContent="flex-end">
        <Box marginBottom="$tiny">
          <Button
            width={120}
            variant="primary"
            loading={isLoading}
            onPress={onSubmit}
          >
            {content?.ctas?.confirm}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneConfirmationForm;
